import { ReactElement } from 'react'

import { AmazonOutlined } from '@ant-design/icons'

import { asinUrl } from 'helpers/urls'
import { CountryCode } from 'types'

interface Props {
    asin: string
    countryCode: CountryCode
    showAmazonIcon?: boolean
}

function AsinLink({
    asin,
    countryCode,
    showAmazonIcon = true,
}: Props): ReactElement | null {
    return asin ? (
        <a
            href={asinUrl(asin, countryCode)}
            target="_blank"
            rel="noopener noreferrer"
        >
            {asin} {showAmazonIcon && <AmazonOutlined />}
        </a>
    ) : null
}

export default AsinLink
