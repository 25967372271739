import { ReactElement } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import sortBy from 'lodash/fp/sortBy'
import truncate from 'lodash/truncate'
import { useSelector } from 'react-redux'

import { DEFAULT_TOOLTIP, DEFAULT_TITLE } from 'configuration/charts'
import { DATA_SOURCES } from 'configuration/widgets'
import { PIE, COLUMN, BUBBLE } from 'const/widgets'
import {
    DEFAULT_X_AXIS,
    getYAxisOptions,
    DEFAULT_HORIZONTAL_LEGEND,
} from 'helpers/chart'
import {
    formatLegendItem,
    formatDataLabel,
    getSeriesName,
    getSeriesColor,
} from 'helpers/charts'
import { formatNumber } from 'helpers/formatting'
import { elementToString } from 'helpers/utilities'
import { transformToString } from 'helpers/widgets'
import {
    selectAutomationCapabilitiesMap,
    selectBrandGroupsMap,
} from 'selectors/ui'
import { FieldMapping, Widget } from 'types'

import { chartTooltipFormatter } from './helpers/tooltip'

interface Props {
    widget: Widget
    widgetData: any[]
    metricsConfig: FieldMapping
}

function CategoryChartWidget({
    widget,
    widgetData,
    metricsConfig,
}: Props): ReactElement {
    const automationCapabilitiesMap = useSelector(
        selectAutomationCapabilitiesMap
    )
    const brandGroupsMap = useSelector(selectBrandGroupsMap)

    const widgetMetrics = transformToString(widget.metrics)
    const metric = widgetMetrics[0]
    const groupBy = transformToString(widget.group_by)[0]

    const dataSource = DATA_SOURCES[widget.data_source]
    const groupByConfigMap = dataSource?.groupByConfig
    const groupByConfig = groupByConfigMap ? groupByConfigMap[groupBy] : {}

    const metricConfig = metricsConfig[metric]

    const countTypes = ['count', 'currencyAmount']
    const showPercent = metricConfig?.metricOptions?.type
        ? countTypes.includes(metricConfig.metricOptions.type)
        : false

    const getPercentage = (percentage: number): string | undefined => {
        if ((!widget.chart_type || widget.chart_type === PIE) && showPercent) {
            return formatNumber(percentage / 100, '0%')
        }
        return undefined
    }

    return (
        <HighchartsReact
            immutable
            highcharts={Highcharts}
            options={{
                chart: {
                    type: widget.chart_type || PIE,
                    marginTop: widget.chart_type === COLUMN ? 50 : undefined,
                },
                legend: {
                    ...DEFAULT_HORIZONTAL_LEGEND,
                    squareSymbol: false,
                    labelFormatter() {
                        const { visible, name, color } = this as any
                        return formatLegendItem(
                            visible,
                            elementToString(name),
                            color,
                            undefined
                        )
                    },
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            useHTML: true,
                            formatter() {
                                const { point, key, percentage } = this as any
                                const formattedValue = formatNumber(
                                    point.y,
                                    metricConfig?.metricOptions?.shortFormat
                                )

                                // Assuming getPercentage(percentage) returns the percentage string
                                // Wrap the percentage in a span with a style for the color red
                                const formattedPercentage =
                                    getPercentage(percentage)
                                const percentageText = formattedPercentage
                                    ? `<span style="color: inherit;">${formattedPercentage}</span>`
                                    : ''

                                return formatDataLabel(
                                    point.color,
                                    truncate(elementToString(key)),
                                    formattedValue,
                                    percentageText // Pass the styled percentage text
                                )
                            },
                        },
                        showInLegend: true,
                        innerSize: '50%',
                    },
                    column: {
                        showInLegend: false,
                    },
                    bubble: {
                        showInLegend: false,
                        innerSize: '50%',
                    },
                    series: {
                        animation: false,
                    },
                },
                series: [
                    {
                        data: flow(
                            map((record: any) => {
                                const name = elementToString(
                                    getSeriesName(
                                        record,
                                        record[groupBy],
                                        groupByConfig,
                                        {
                                            automationCapabilitiesMap,
                                        }
                                    )
                                )
                                const dataPoints = {
                                    x: undefined,
                                    y: record[metric],
                                    z: undefined,
                                }
                                if ((widget.chart_type as string) === BUBBLE) {
                                    if (widgetMetrics.length > 1) {
                                        dataPoints.x = record[widgetMetrics[1]]
                                    }
                                    if (widgetMetrics.length > 2) {
                                        dataPoints.z = record[widgetMetrics[2]]
                                    }
                                }
                                return {
                                    name,
                                    ...dataPoints,
                                    color: getSeriesColor(
                                        record,
                                        name,
                                        groupByConfig,
                                        {
                                            brandGroupsMap,
                                        }
                                    ),
                                }
                            }),
                            sortBy((record) =>
                                record.name === 'Others' ? 1 : 0
                            )
                        )(widgetData),
                    },
                ],
                title: DEFAULT_TITLE,
                tooltip: {
                    ...DEFAULT_TOOLTIP,
                    formatter() {
                        const { point } = this as any
                        return chartTooltipFormatter({
                            point,
                            widget,
                            metricsConfig,
                        })
                    },
                },
                xAxis: (() => {
                    if ((widget.chart_type as string) === BUBBLE) {
                        return {
                            ...DEFAULT_X_AXIS,
                            title: {
                                text: elementToString(
                                    metricsConfig[widgetMetrics[1]]
                                        ?.shortName ?? ''
                                ),
                                style: {
                                    whiteSpace: 'nowrap',
                                },
                            },
                        }
                    }
                    return {
                        ...DEFAULT_X_AXIS,
                        type: 'category',
                    }
                })(),
                //                 }
                yAxis: getYAxisOptions({
                    titleText:
                        (widget.chart_type as string) === BUBBLE
                            ? elementToString(
                                  metricsConfig[widgetMetrics[0]]?.shortName ??
                                      ''
                              )
                            : elementToString(metricConfig?.name ?? ''),
                    verticalTitle: (widget.chart_type as any) === BUBBLE,
                    color: '#aaa',
                    valueFormatter: (value) => {
                        let formattedValue = value

                        if (metricConfig) {
                            formattedValue = formatNumber(
                                value,
                                metricConfig?.metricOptions?.shortFormat
                            )
                        }

                        return `<span style="color: #aaa;">${formattedValue}</span>`
                    },
                }),
            }}
        />
    )
}

export default CategoryChartWidget
