import {
    ReactElement,
    ComponentClass,
    JSXElementConstructor,
    LazyExoticComponent,
    ComponentType,
} from 'react'

import { useSelector } from 'react-redux'
import { RouteComponentProps, RouteProps } from 'react-router-dom'

import { LoadingIndicator } from 'components/LoadingIndicator'
import { dynamicReducerKeys } from 'reducers/ui/ui'
import { selectDomainValue as selectUiDomainValue } from 'selectors/ui'
import { RootReduxState } from 'types'

interface InnerProps extends RouteProps {
    pageName?: string
}

export default function DynamicPageRoute<C>(
    PageComponent: ComponentType<any>
):
    | LazyExoticComponent<ComponentClass<C & RouteComponentProps<any>>>
    | JSXElementConstructor<C & RouteComponentProps<any>>
    | ComponentClass<C & RouteComponentProps<any>> {
    return ({ pageName = '', ...props }: InnerProps): ReactElement => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const isReducerHydrated = useSelector((state: RootReduxState) =>
            selectUiDomainValue(state, [pageName, 'hydrated'])
        )
        if (
            (dynamicReducerKeys as readonly string[]).includes(pageName) &&
            !isReducerHydrated
        ) {
            return <LoadingIndicator size="small" />
        }
        return <PageComponent {...(props as any)} />
    }
}
