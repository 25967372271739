import { ReactElement } from 'react'

import { RankingCategory } from 'types'

import styles from './styles.scss'

interface Props {
    bestRankingCategory: string
    otherRankings: RankingCategory[]
}

const RankingCategoriesCell = ({
    bestRankingCategory,
    otherRankings,
}: Props): ReactElement => {
    return (
        <div className={styles.cell}>
            <div className={styles.root}>Ranked in: {bestRankingCategory}</div>
            <div>
                {otherRankings.map((ranking) => (
                    <div key={ranking.id} className={styles.rank}>
                        #{ranking.rank} in {ranking.name}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default RankingCategoriesCell
