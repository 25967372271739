/**
 * An array of strings or regexps evaluated against error messages for error suppression.
 */
export const ignoreErrors: (string | RegExp)[] = [
    'Non-Error exception captured', // sentry may catch Non-error during HMR
    'ResizeObserver loop limit exceeded', // https://stackoverflow.com/a/50387233/3112403
    'Not authorized.', // Not authorized errors are expected
    /^Request failed$/, // Error message on 401 cerebro responses now
    'SovKeyword duplicates are not allowed',
    /SOV keywords are capped at \d/, // sov cap errors
    /Missing credentials in config, if using AWS_CONFIG_FILE, set AWS_SDK_LOAD_CONFIG=\d/, // known aws-sdk-js issue,
    'ResizeObserver loop completed with undelivered notifications.',
    /^Network Error$/,
    'The provided key element does not match the schema',
    'Label name conflicts with existing label',
    'You have reached the user limit for this organization',
    'The keyword already exists',
    'This invitation has expired and can no longer be accepted',
    'Can only update pending invites',
    // ----------------
    // These are errors that occur when we deploy a new version of the app.
    // The app automatically reloads when encountering these
    // so they are expected and are not surfaced to the user.
    /Unexpected token '<'/,
    /Loading chunk \d+ failed/,
    // ----------------
]

/**
 * List of URLs to exclude for calculating performance metrics in Datadog
 *
 * See: https://docs.datadoghq.com/real_user_monitoring/browser/monitoring_page_performance/#monitoring-single-page-applications-spa
 */
export const excludedActivityUrls: RegExp[] = [
    /^.+b\.6sc\.co.+$/,
    /^.+rs\.fullstory\.com.+$/,
    /^.+r\.lr-ingest\.io.+$/,
]
