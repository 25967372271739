import find from 'lodash/find'
import get from 'lodash/get'
import { all, call, select, put } from 'redux-saga/effects'

import {
    makeDownloadTableSuccess,
    makeFetchTableRequest,
    makeFetchTableSuccess,
} from 'actions/ui/shared'
import { keywordsTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { AD_GROUP_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/keywordFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatSorter,
    formatMetrics,
    formatPagination,
    formatCurrency,
    formatPeriodDeltaDateRange,
    formatColumns,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectResourceOfPage,
    selectResourceParamsOfPage,
    selectVisibleMetricsOfTable,
    selectCurrencyCode,
    selectVisibleCombinedFilters,
    selectTableSettings,
    selectVisibleColumnsOfTable,
} from 'selectors/ui'
import {
    getKeywordFacts,
    getKeywordFactsExport,
} from 'services/cerebroApi/orgScope/keywordFactsApi'
import { createKeyword } from 'services/cerebroApi/orgScope/resourceApi'
import message from 'utilities/message'

import { fetchKeywordBidRecommendationsSaga } from '../../shared/bid/workers'

const TAB_PATH = [AD_GROUP_PAGE, 'keywords']
const TABLE_PATH = [...TAB_PATH, 'table']

function* selectCampaignId() {
    const adGroup = yield select(selectResourceOfPage, AD_GROUP_PAGE)

    return get(adGroup, 'campaign_id')
}

function* selectAdGroupId() {
    return yield select(selectResourceParamsOfPage, AD_GROUP_PAGE)
}

function* fetchTableSaga(noCount) {
    const { adGroupId } = yield call(selectAdGroupId)
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        keywordsTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)

    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatMetrics(metrics, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        ad_group: adGroupId,
        group_by: 'keyword_id,profile_id,ad_group_id',
    }

    const response = yield call(cerebroApiSaga, null, getKeywordFacts, params, {
        headers: { noCount },
    })

    if (response) {
        yield put(makeFetchTableSuccess(TABLE_PATH)(response.data))

        const columns = yield select(
            selectVisibleColumnsOfTable,
            TABLE_PATH,
            keywordsTableColumnsConfig
        )

        if (find(columns, { id: 'keyword_suggested_bid' })) {
            yield call(
                fetchKeywordBidRecommendationsSaga,
                TABLE_PATH,
                response.data.results
            )
        }
    }
}

function* createKeywordSaga({ keywords }) {
    const campaign_id = yield selectCampaignId()
    const { adGroupId: ad_group_id } = yield selectAdGroupId()

    const keywordsToCreate = keywords.map((keyword) => ({
        ...keyword,
        campaign_id,
        ad_group_id,
    }))

    yield all(
        keywordsToCreate.map((keyword) =>
            call(cerebroApiSaga, null, createKeyword, keyword)
        )
    )

    message.success('Keywords successfully created!')
}

function* downloadTableSaga() {
    const adGroup = yield select(selectResourceOfPage, AD_GROUP_PAGE)
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        TABLE_PATH,
        keywordsTableColumnsConfig
    )

    const currency = yield select(selectCurrencyCode)
    const reportName = `Keyword Report - ${adGroup.campaign.name} - ${adGroup.name}`
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        ad_group: adGroup.id,
        campaign: adGroup.campaign_id,
        group_by: 'keyword_id',
        async_download_name: reportName,
    }

    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(TABLE_PATH),
        getKeywordFactsExport,
        params
    )

    generateReportNotification(reportName)
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    createTableRecordSaga: createKeywordSaga,
    downloadTableSaga,
})
