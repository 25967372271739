import cloneDeep from 'lodash/cloneDeep'
import { handleActions } from 'redux-actions'

import { clearAsinUsage, updateAsinUsage } from 'actions/asinUsage'
import { AsinUsage } from 'types/redux/asinUsage'

const defaultState: AsinUsage = {
    usage: 0,
    orgLimit: 0,
    usageDetails: [],
}

export default handleActions(
    {
        [updateAsinUsage.toString()]: (state, action): AsinUsage => ({
            ...state,
            ...action.payload,
        }),
        [clearAsinUsage.toString()]: (): AsinUsage => ({
            ...defaultState,
        }),
    },
    cloneDeep(defaultState)
)
