import moment from 'moment'

import {
    COUNTRIES,
    DATES,
    BRANDS,
    SC_ACCOUNTS,
    SC_INTEGRATIONS,
    DSP_ADVERTISERS,
    METRIC_FILTERS,
    ORGANIZATIONS,
} from 'const/filters'
import { MAX_DAYS_RANGE_FOR_HOURLY_DATA } from 'const/widgets'
import { calculateFromDateWithLag } from 'helpers/dateRange'
import { moveDateBetweenTimeZones, PACIFIC_TIMEZONE, UTC } from 'helpers/dates'
import { isNonEmptyArray } from 'helpers/typeGuard'
import { formatDateFromFilter } from 'helpers/utils'
import { FiltersState, OrgFactsParams } from 'types'

import { formatMetricFiltersForParams } from './metrics'

export const formatFilters = (
    filters: FiltersState,
    hasHourlyData = false
): OrgFactsParams => {
    let params: OrgFactsParams = {}

    const dates = filters[DATES]
    let { start, end } = formatDateFromFilter(
        dates,
        calculateFromDateWithLag(filters.rangeLag),
        filters.rangeLag
    )

    if (!!start && !!end) {
        const dateRangeInDays = moment(end).diff(moment(start), 'days')
        if (hasHourlyData && dateRangeInDays < MAX_DAYS_RANGE_FOR_HOURLY_DATA) {
            start = moveDateBetweenTimeZones(
                `${start}T00:00`,
                PACIFIC_TIMEZONE,
                UTC
            )
            end = moveDateBetweenTimeZones(
                `${end}T23:59`,
                PACIFIC_TIMEZONE,
                UTC
            )
        }

        params.report_date_min = start
        params.report_date_max = end
    }

    const countries = filters[COUNTRIES]
    if (isNonEmptyArray(countries)) {
        params.country__in = countries.map((item) => item.value).join()
    }

    const brands = filters[BRANDS]
    if (isNonEmptyArray(brands)) {
        params.profile__in = brands.map((item) => item.value).join()
    }

    if (isNonEmptyArray(filters[SC_ACCOUNTS])) {
        params.seller_central_account_id__in = filters[SC_ACCOUNTS].map(
            (item) => item.value
        ).join()
    } else if (isNonEmptyArray(filters[SC_INTEGRATIONS])) {
        params.seller_central_account__integrations__id__in = filters[
            SC_INTEGRATIONS
        ].map((item) => item.value).join()
    }

    const advertisers = filters[DSP_ADVERTISERS]
    if (isNonEmptyArray(advertisers)) {
        params.advertiser_id__in = advertisers.map((item) => item.value).join()
    }

    const metricFilters = filters[METRIC_FILTERS]
    if (isNonEmptyArray(metricFilters)) {
        params = { ...params, ...formatMetricFiltersForParams(metricFilters) }
    }

    const organizations = filters[ORGANIZATIONS]
    if (isNonEmptyArray(organizations)) {
        params.organization_id__in = organizations
            .map((item) => item.value)
            .join()
    }

    return params
}
