import { useFlags } from 'launchdarkly-react-client-sdk'

// Flags can be any JSON serializable type, not just boolean
// Declare all flags here to keep them in one place
// Note: flag names in the LaunchDarkly UI are
// transformed to camelCase in the React SDK
interface FeatureFlagsSet {
    dateRangeAlignment: boolean
    showDataSource: boolean
    groupByTooltips: boolean
    preReleaseMetrics: boolean
    segmentTopKeywords: boolean
    insights: boolean
    segmentCsvExportEnabled: boolean
    segmentCsvExportHidden: boolean
    asinUsageFlatTable: boolean
    blockActivateSegmentOverLimit: boolean
    showAlertForSegmentsQueuedForCollection: boolean
    hideSegmentPriorPeriodDataWhenExtends2Years: boolean
}

// Return a typed set of flags for better typing
// and to centralize dependency on the feature flag library
function useFeatureFlags(): FeatureFlagsSet {
    return useFlags<FeatureFlagsSet>()
}

export default useFeatureFlags
