import { ColorString, GradientColorObject, PatternObject } from 'highcharts'
import get from 'lodash/get'

import { GROUPING_COLORS } from 'configuration/charts'
import { CURRENCIES } from 'configuration/currency'
import { AD_FORMAT_LABELS } from 'const/adFormats'
import { COUNTRY_LABELS, LANGUAGE_LABELS, REGION_LABELS } from 'const/codes'
import { FACT_TYPE_LABELS } from 'const/factTypes'
import { UNDEFINED_BRAND, UNDEFINED_GROUP_BY_VALUE } from 'const/formatting'
import { OTHER_LABEL_ID } from 'const/labels'
import { ChartFormatter, ChartFormatters } from 'types'

import { propToColor, stringToColor } from './colors'
import {
    formatAccountType,
    formatBrandName,
    formatDayOfWeekFromRecord,
    formatHourOfDayFromRecord,
    formatMonthNameFromRecord,
    formatMonthYearNameFromRecord,
    titleCase,
} from './formatting'
import { elementToString, isUnset } from './utilities'

type ColorType = ColorString | GradientColorObject | PatternObject | undefined

export const formatTooltip = (
    color: ColorType,
    label: string | undefined,
    value: any,
    pctValue?: any,
    symbol?: any,
    percentageTypeMetric?: boolean
): string => {
    symbol?.symbol?.attr({ x: 0, y: 0, height: 10, width: 10 })
    const indicator = symbol?.symbol
        ? `<svg style="margin-right: 5px;" width='11' height='10'>${symbol.symbol.element.outerHTML}</svg>`
        : ''

    const pctValueEl = pctValue
        ? `<div class="point-value-pct">(${pctValue})</div>`
        : ''

    const showOnlyPctValue = percentageTypeMetric && pctValue
    const updatedValue = showOnlyPctValue ? pctValue : value

    return `
        <div>
            <div class="point-label" style="color: ${color};">${indicator} ${label}: </div>
            <div class="point-value">${updatedValue}</div>
            ${showOnlyPctValue ? '' : pctValueEl}
        </div>
    `
}

export const formatDataLabel = (
    color: ColorType,
    label: string,
    value: any,
    pctValue?: any
): string => `
    <div class="data-label">
        <div class="label-wrapper">
            <span class="data-label-dot" style="background-color: ${color};"></span>
            <span class="label-name">${label}:</span>
        </div>
        <div class="label-wrapper value">
            <span class="label-value">${value}</span>
            ${
                pctValue
                    ? `<span class="percentage-value">(${pctValue})</span>`
                    : ''
            }
        </div>
    </div>
    `
export const formatLegendItem = (
    visible: boolean,
    nameString: string,
    color: ColorType,
    indicatorStyles: string | undefined,
    itemBorderColor: ColorType = undefined,
    legendSymbol: any = undefined
): string => {
    legendSymbol?.symbol?.attr({
        x: 1,
        y: 1,
        height: 9,
        width: 9,
        stroke: color,
        strikeWidth: 2,
    })
    const indicatorStyleString = indicatorStyles || `background: ${color};`
    const itemStyleString = itemBorderColor
        ? `style="border-color: ${itemBorderColor}"`
        : ''
    const indicator = legendSymbol?.symbol
        ? `<svg style="margin-right: 5px;" width='11' height='11'>${legendSymbol.symbol.element.outerHTML}</svg>`
        : `<span class="indicator" style="${indicatorStyleString}"></span>`
    return `
        <div class="${
            visible ? 'active' : ''
        }" title="${nameString}" ${itemStyleString}>
            ${indicator}
            <span class="label">${nameString}</span>
        </div>
    `
}

export const formatTimeSeriesLegendItem = ({
    visible,
    name,
    isColumn,
    isPriorPeriod,
    bgColor,
    indicatorBorderColor,
    itemBorderColor,
    legendSymbol,
}: {
    visible: boolean
    name: string
    isColumn: boolean
    isPriorPeriod: boolean
    bgColor: ColorType
    indicatorBorderColor: ColorType
    itemBorderColor: ColorType
    legendSymbol: any
}): string => {
    const nameString = `${elementToString(name)} ${
        isPriorPeriod ? '(Prior)' : ''
    }`

    let indicatorStyles
    if (isPriorPeriod) {
        indicatorStyles = isColumn
            ? `background: ${bgColor}; border: 1px solid ${indicatorBorderColor};`
            : `background: '#fff'; border: 2px solid ${indicatorBorderColor};`
    }

    return formatLegendItem(
        visible,
        nameString,
        indicatorBorderColor,
        indicatorStyles,
        itemBorderColor,
        legendSymbol
    )
}

export const getSeriesName = (
    record: any,
    group: string,
    groupByConfig: ChartFormatters | any,
    options: any
): string => {
    if (group === 'Others') {
        return 'Others'
    }

    if (groupByConfig && groupByConfig.displayName) {
        return groupByConfig.displayName(record, options)
    }

    return group || UNDEFINED_GROUP_BY_VALUE
}

export const getSeriesColor = (
    record: any,
    name: string,
    groupByConfig?: ChartFormatter,
    options?: any
): string => {
    let color
    if (groupByConfig && groupByConfig.color) {
        color = groupByConfig.color(record, options)
    }
    if (!color) {
        color = name && stringToColor(name)
    }
    return color
}

const formatLabel = (
    record: any,
    id_field: string,
    metadata_field: string
): string => {
    if (record[id_field] === OTHER_LABEL_ID) {
        return 'Others'
    }
    return record[metadata_field] ? record[metadata_field].name : 'No Label'
}

const getLabelGroupByConfig = (prefix: string): ChartFormatter => ({
    displayName: (record) =>
        formatLabel(record, `${prefix}__label_id`, `${prefix}__label`),
    color: (record) => get(record, [`${prefix}__label`, 'color']),
})

export const DATE_GROUP_BY_FORMATTERS: ChartFormatters = {
    report_date_week_day: {
        displayName: (record) =>
            formatDayOfWeekFromRecord(record, 'report_date_week_day'),
        color: (record) => propToColor(record, 'report_date_week_day'),
    },
    report_date_month_number: {
        displayName: (record) =>
            formatMonthNameFromRecord(record, 'report_date_month_number'),
        color: (record) => propToColor(record, 'report_date_month_number'),
    },
    report_date_hour_of_day: {
        displayName: (record) =>
            formatHourOfDayFromRecord(record, 'report_date_hour_of_day'),
        color: (record) =>
            get(GROUPING_COLORS, [
                'report_date_hour_of_day',
                record.report_date_hour_of_day,
            ]),
    },
    report_date_month: {
        displayName: (record) =>
            formatMonthYearNameFromRecord(record, 'report_date_month'),
        color: (record) => propToColor(record, 'report_date_month'),
    },
}

/**
 * Used in rendering chart series/labels
 */
export const AMS_CHART_FORMATTERS: ChartFormatters = {
    profile_id: {
        displayName: (record) =>
            record.profile ? `${formatBrandName(record.profile)}` : 'No Brand',
    },
    profile__label_id: getLabelGroupByConfig('profile'),
    profile__region: {
        displayName: (record) =>
            REGION_LABELS[record.profile__region] || 'Unknown Region',
        color: (record) =>
            get(GROUPING_COLORS, ['region', record.profile__region]),
    },
    profile__country_code: {
        displayName: (record) =>
            COUNTRY_LABELS[record.profile__country_code] || 'Unknown Country',
        color: (record) =>
            get(GROUPING_COLORS, ['country', record.profile__country_code]),
    },
    profile__currency_code: {
        color: (record) =>
            get(CURRENCIES, [record.profile__currency_code, 'color']),
    },
    profile__type: {
        color: (record) =>
            get(GROUPING_COLORS, ['profile__type', record.profile__type]),
    },
    campaign_id: {
        displayName: (record) =>
            get(record, ['campaign', 'name'], 'No Campaign'),
    },
    campaign__label_id: getLabelGroupByConfig('campaign'),
    campaign__targeting_type: {
        displayName: (record) =>
            record.campaign__targeting_type
                ? titleCase(record.campaign__targeting_type)
                : 'No Targeting Type',
        color: (record) =>
            get(GROUPING_COLORS, [
                'campaign__targeting_type',
                record.campaign__targeting_type,
            ]),
    },
    campaign__campaign_type: {
        displayName: (record) =>
            get(FACT_TYPE_LABELS, [record.campaign__campaign_type]),
        color: (record) =>
            get(GROUPING_COLORS, [
                'campaign__campaign_type',
                record.campaign__campaign_type,
            ]),
    },
    campaign__ad_format: {
        displayName: (record) => {
            if (isUnset(record.campaign__ad_format)) {
                return UNDEFINED_GROUP_BY_VALUE
            }
            return get(AD_FORMAT_LABELS, [record.campaign__ad_format])
        },
    },
    campaign__state: {
        displayName: (record) => titleCase(record.campaign__state),
        color: (record) =>
            get(GROUPING_COLORS, ['state', record.campaign__state]),
    },
    keyword_id: {
        displayName: (record) =>
            `${get(record, 'keyword.text', '')} - (${get(
                record,
                'keyword.campaign.name',
                ''
            )})`,
    },
    keyword__match_type: {
        color: (record) =>
            get(GROUPING_COLORS, [
                'keyword__match_type',
                record.keyword__match_type,
            ]),
    },
    keyword__state: {
        color: (record) =>
            get(GROUPING_COLORS, ['state', record.keyword__state]),
    },
    product_ad_id: {
        displayName: (record) =>
            get(
                record,
                ['product_ad', 'product_metadata', 'title'],
                get(record, ['product_ad', 'asin', ''])
            ),
    },
    product_ad__state: {
        color: (record) =>
            get(GROUPING_COLORS, ['state', record.product_ad__state]),
    },
    metadata_id: {
        displayName: (record) =>
            get(record, ['metadata', 'title'], 'No Product Metadata'),
    },
    product__label_id: getLabelGroupByConfig('product'),
    ad_group_id: {
        displayName: (record) =>
            `${get(record, 'ad_group.name', '')} - (${get(
                record,
                'ad_group.campaign.name',
                ''
            )})`,
    },
    portfolio_id: {
        displayName: (record) =>
            get(record, ['portfolio', 'name'], 'No Portfolio'),
    },
    portfolio__label_id: getLabelGroupByConfig('portfolio'),
    automation_capability_id: {
        displayName: (record, { automationCapabilitiesMap }) =>
            get(
                automationCapabilitiesMap,
                [record.automation_capability_id, 'name'],
                'No Automation'
            ),
    },
    ...DATE_GROUP_BY_FORMATTERS,
}

/**
 * Used in rendering chart series/labels
 */
export const SOV_CHART_FORMATTERS: ChartFormatters = {
    keyword_id: {
        displayName: (record) =>
            record.keyword_id === '-1'
                ? 'Others'
                : get(record, 'keyword.text', ''),
    },
    keyword__label_id: getLabelGroupByConfig('keyword'),
    keyword__language_code: {
        displayName: (record) =>
            LANGUAGE_LABELS[record.keyword__language_code] ||
            'Unknown Language',
        color: (record) =>
            get(GROUPING_COLORS, ['language', record.keyword__language_code]),
    },
    keyword__platform: {
        color: (record) =>
            get(GROUPING_COLORS, [
                'keyword__platform',
                record.keyword__platform,
            ]),
    },
    keyword__country_code: {
        displayName: (record) =>
            COUNTRY_LABELS[record.keyword__country_code] || 'Unknown Country',
        color: (record) =>
            get(GROUPING_COLORS, ['country', record.keyword__country_code]),
    },
    product__label_id: getLabelGroupByConfig('product'),
    amazon_choice: {
        displayName: (record) => {
            if (isUnset(record.amazon_choice)) {
                return UNDEFINED_GROUP_BY_VALUE
            }
            return record.amazon_choice ? 'Amazon Choice' : 'Not Amazon Choice'
        },
    },
    best_seller: {
        displayName: (record) => {
            if (isUnset(record.best_seller)) {
                return UNDEFINED_GROUP_BY_VALUE
            }
            return record.best_seller ? 'Best Seller' : 'Not Best Seller'
        },
    },
    review_star_floor: {
        displayName: (record) => {
            return record.review_star_floor
                ? record.review_star_floor.toString()
                : UNDEFINED_GROUP_BY_VALUE
        },
    },
    metadata__brand: {
        displayName: (record) =>
            record.metadata__brand ? record.metadata__brand : UNDEFINED_BRAND,
        color: (record, { brandGroupsMap }) =>
            get(brandGroupsMap, [record.metadata__brand, 'color']),
    },
    result_type: {
        color: (record) =>
            get(GROUPING_COLORS, ['result_type', record.result_type]),
    },
    search_time_week_day: {
        displayName: (record) =>
            formatDayOfWeekFromRecord(record, 'search_time_week_day'),
        color: (record) => propToColor(record, 'search_time_week_day'),
    },
}

export const CHANGELOG_CHART_FORMATTERS: ChartFormatters = {
    object_type: {
        displayName: (record) => titleCase(record.object_type),
    },
    automation_capability_id: {
        displayName: (record, { automationCapabilitiesMap }) =>
            get(
                automationCapabilitiesMap,
                [record.automation_capability_id, 'name'],
                UNDEFINED_GROUP_BY_VALUE
            ),
    },
    change_reason: {
        displayName: (record) =>
            record.change_reason || UNDEFINED_GROUP_BY_VALUE,
    },
    campaign_id: {
        displayName: (record) =>
            get(record, ['campaign', 'name'], 'No Campaign'),
    },
    profile_id: {
        displayName: (record) =>
            record.profile ? `${formatBrandName(record.profile)}` : 'No Brand',
    },
    region: {
        displayName: (record) =>
            REGION_LABELS[record.region] || 'Unknown Region',
        color: (record) => get(GROUPING_COLORS, ['region', record.region]),
    },
    country_code: {
        displayName: (record) =>
            COUNTRY_LABELS[record.country_code] || 'Unknown Country',
        color: (record) =>
            get(GROUPING_COLORS, ['country', record.country_code]),
    },
    user_id: {
        displayName: (record) =>
            get(record, ['user', 'email'], UNDEFINED_GROUP_BY_VALUE),
    },
}

export const VC_CHART_FORMATTERS: ChartFormatters = {
    product__label_id: getLabelGroupByConfig('product'),
    metadata_id: {
        displayName: (record) =>
            get(record, ['metadata', 'title'], 'No Product Metadata'),
    },
    metadata__brand: {
        displayName: (record) =>
            record.metadata__brand ? record.metadata__brand : UNDEFINED_BRAND,
        color: (record, { brandGroupsMap }) =>
            get(brandGroupsMap, [record.metadata__brand, 'color']),
    },
    marketplace: {
        displayName: (record) =>
            COUNTRY_LABELS[record.marketplace] || 'Unknown Marketplace',
        color: (record) =>
            get(GROUPING_COLORS, ['country', record.marketplace]),
    },
    metadata__marketplace: {
        displayName: (record) =>
            COUNTRY_LABELS[record.metadata__marketplace] ||
            'Unknown Marketplace',
        color: (record) =>
            get(GROUPING_COLORS, ['country', record.metadata__marketplace]),
    },
    ...DATE_GROUP_BY_FORMATTERS,
}

export const SC_CHART_FORMATTERS = { ...VC_CHART_FORMATTERS }

export const DSP_CHART_FORMATTERS: ChartFormatters = {
    profile_id: AMS_CHART_FORMATTERS.profile_id,
    advertiser_id: {
        displayName: (record) =>
            record.advertiser ? record.advertiser.name : 'No Advertiser',
    },
    order_id: {
        displayName: (record) =>
            record.order ? record.order.name : 'No Order',
    },
    line_item_id: {
        displayName: (record) =>
            record.line_item ? record.line_item.name : 'No Line Item',
    },
    order__label_id: getLabelGroupByConfig('order'),
    line_item__label_id: getLabelGroupByConfig('line_item'),
    ...DATE_GROUP_BY_FORMATTERS,
}

export const WM_MARKETPLACE_FORMATTERS = { ...DATE_GROUP_BY_FORMATTERS }
export const WM_ADVERTISING_FORMATTERS: ChartFormatters = {
    platform: {
        displayName: (record) =>
            titleCase(record?.platform) ?? UNDEFINED_GROUP_BY_VALUE,
        color: (record) =>
            stringToColor(record?.platform ?? UNDEFINED_GROUP_BY_VALUE),
    },
    ...DATE_GROUP_BY_FORMATTERS,
}

export const ORG_FACT_CHART_FORMATTERS: ChartFormatters = {
    country: {
        displayName: (record) =>
            COUNTRY_LABELS[record.country] || 'Unknown Country',
        color: (record) => get(GROUPING_COLORS, ['country', record.country]),
    },
    organization_id: {
        displayName: (record) =>
            record.organization?.name || 'Unknown Organization',
    },
    account_type: {
        displayName: (record) => formatAccountType(record.account_type),
        color: (record) =>
            get(GROUPING_COLORS, ['profile__type', record.account_type]),
    },
    ...DATE_GROUP_BY_FORMATTERS,
}
