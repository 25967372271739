import { ReactElement, useCallback, CSSProperties } from 'react'

import { Select, Spin, Tooltip } from 'antd'

import {
    SEARCH_RESULTS_PER_QUERY,
    PREFETCH_RESULTS_PER_QUERY,
} from 'configuration/typeahead'
import { formatAdAccountName } from 'helpers/formatting'
import { useTypeahead } from 'hooks'
import { getCampaigns } from 'services/cerebroApi/orgScope/resourceApi'
import { Campaign } from 'types'

interface Props {
    value?: string
    onChange: (value: string) => void
    defaultOptions?: Campaign[]
    extraAPIParams?: object
    className?: string
    style?: CSSProperties
    disabled?: boolean
}

const CampaignSearchSelect = ({
    value,
    onChange,
    defaultOptions = [],
    extraAPIParams = {},
    className = '',
    style = { width: '100%' },
    disabled = false,
}: Props): ReactElement => {
    const extrasStr = JSON.stringify(extraAPIParams)
    const [options, loading, onSearch, resetLoadingState] =
        useTypeahead<Campaign>({
            apiSearchFunc: (query) =>
                getCampaigns(
                    {
                        limit: SEARCH_RESULTS_PER_QUERY,
                        name__similar: query,
                        'state!': 'archived',
                        ...extraAPIParams,
                    },
                    { headers: { noCount: true } }
                ),
            optionFormatter: (campaign) => ({
                value: campaign.id,
                label: campaign.name,
                isDisabled: !campaign.ad_groups.length,
                metadata: formatAdAccountName(campaign.profile),
            }),
            defaultOptions: defaultOptions?.filter((option) => Boolean(option)),
            prefetchApiFunc: useCallback(
                () =>
                    getCampaigns(
                        {
                            limit: PREFETCH_RESULTS_PER_QUERY,
                            'state!': 'archived',
                            ...JSON.parse(extrasStr),
                        },
                        { headers: { noCount: true } }
                    ),
                [extrasStr]
            ),
        })

    return (
        <Select
            filterOption={false}
            optionLabelProp="title"
            placeholder="Search Campaigns..."
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={resetLoadingState}
            showSearch
            style={style}
            className={className}
            value={value}
            disabled={disabled}
        >
            {options.map((option) => (
                <Select.Option
                    key={option.value}
                    value={option.value}
                    title={option.label}
                    disabled={option.isDisabled}
                >
                    <Tooltip
                        title={
                            option.isDisabled
                                ? 'Campaign can not be selected since it contains no ad groups.'
                                : undefined
                        }
                    >
                        <div>
                            <b>{option.label}</b> <i>({option.metadata})</i>
                        </div>
                    </Tooltip>
                </Select.Option>
            ))}
        </Select>
    )
}

export default CampaignSearchSelect
