import { ReactElement } from 'react'

import keys from 'lodash/keys'
import lowerCase from 'lodash/lowerCase'
import { Link } from 'react-router-dom'

import { AutomationTypeContainer } from 'components/AutomationType'
import { UNDEFINED_VALUE } from 'const/formatting'
import {
    AUTOMATION_TASK,
    CAMPAIGN,
    KEYWORD,
    PRODUCT_AD,
} from 'const/resourceTypes'
import { CHANGE_TERM_FORMATTERS } from 'helpers/changelog'
import { changelogResourceUrl } from 'helpers/urls'
import { isUnset } from 'helpers/utilities'
import { ChangelogRecord, CellRenderProps } from 'types'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex?: string[]
}

function changelogObjectNameWithAutomationProfileRenderer<
    RecordType extends ChangelogRecord,
>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    if (isUnset(value)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    const resourceLabel =
        record.object_type === AUTOMATION_TASK
            ? record.automation_profile?.name
            : value
    return record.automation_profile ? (
        <span style={{ display: 'flex', gap: '0.5rem' }}>
            <Link
                to={changelogResourceUrl(
                    record.object_type,
                    record.automation_profile.id,
                    record.campaign
                )}
            >
                {resourceLabel ?? value}
            </Link>
            {' - '}
            <AutomationTypeContainer
                capabilityId={record.automation_profile.capability_id}
            />
        </span>
    ) : (
        <span>{value}</span>
    )
}

export function changelogObjectNameRenderer<RecordType extends ChangelogRecord>(
    props: RenderProps<RecordType>
): ReactElement {
    const linkableResourceTypes = [
        CAMPAIGN,
        KEYWORD,
        PRODUCT_AD,
        AUTOMATION_TASK,
    ]

    const { value, record } = props.cellRenderProps

    if (isUnset(value)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    if (record.object_type === AUTOMATION_TASK) {
        return changelogObjectNameWithAutomationProfileRenderer(props)
    }

    return linkableResourceTypes.includes(record.object_type) ? (
        <Link
            to={changelogResourceUrl(
                record.object_type,
                record.object_id,
                record.campaign
            )}
        >
            {value}
        </Link>
    ) : (
        <span>{value}</span>
    )
}

interface ChangelogValueProps {
    changedProp: string
    value: string | number | boolean | null
    type: 'old' | 'new'
}

function ChangelogValue(props: ChangelogValueProps): ReactElement {
    const { changedProp, value: raw_value, type } = props
    function formatValue(
        fieldName: string,
        value: string | number | boolean | null
    ): string {
        if (isUnset(value)) {
            return 'None'
        }
        const formatter = (CHANGE_TERM_FORMATTERS as any)[fieldName]
        if (formatter) {
            return formatter(value, value)
        }
        return `${value}`
    }

    return (
        <span
            style={{
                background:
                    type === 'new' ? 'rgba(82, 196, 26, 0.1)' : '#f5f5f5',
                padding: '1px 2px',
                borderRadius: 3,
            }}
        >
            {formatValue(changedProp, raw_value)}
        </span>
    )
}

export function changelogChangesRenderer<RecordType extends ChangelogRecord>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    if (isUnset(value) && isUnset(record)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    const {
        change_type: historyType,
        object_type: objectType,
        changes,
    } = record
    const resourceTypeLabel = lowerCase(objectType)

    if (historyType === '+') {
        return <span>{`Created new ${resourceTypeLabel}`}</span>
    }

    function formatKey(key: string): string {
        let formattedKey = key ?? ''
        if (formattedKey.endsWith('_int')) {
            formattedKey = formattedKey.replace('_int', '')
        }
        return lowerCase(formattedKey)
    }

    const changeList = []
    const allKeys = keys(changes)
    for (let i = 0; i < allKeys.length; i += 1) {
        const key = allKeys[i]
        const change = changes[key]
        if (change) {
            changeList.push(
                <div key={key}>
                    <span style={{ fontWeight: 500 }}>{formatKey(key)}</span>:{' '}
                    <ChangelogValue
                        changedProp={key}
                        value={change.old}
                        type="old"
                    />{' '}
                    →{' '}
                    <ChangelogValue
                        changedProp={key}
                        value={change.new}
                        type="new"
                    />
                </div>
            )
        }
    }

    return <div>{changeList.map((change) => change)}</div>
}
