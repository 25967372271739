import cloneDeep from 'lodash/cloneDeep'
import { combineReducers } from 'redux'
import { ReduxCompatibleReducer } from 'redux-actions'
import { persistReducer, createTransform, PersistConfig } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
    AD_ACCOUNTS_SUMMARY_PAGE,
    CAMPAIGNS_SUMMARY_PAGE,
    CAMPAIGN_PAGE,
    DSP_SUMMARY_PAGE,
    EVENTS_SUMMARY_PAGE,
    LABELS_SUMMARY_PAGE,
    PORTFOLIOS_SUMMARY_PAGE,
    PRODUCTS_SUMMARY_PAGE,
    SOV_KEYWORDS_SUMMARY_PAGE,
    TARGETING_SUMMARY_PAGE,
    WALMART_CAMPAIGNS_SUMMARY_PAGE,
    WALMART_ITEMS_SUMMARY_PAGE,
    WALMART_KEYWORDS_SUMMARY_PAGE,
} from 'const/pages'
import { FILTER_SETTINGS, FILTERS } from 'const/reducerKeys'
import { GenericObject } from 'types'
import { deepPick, deepOverride } from 'utilities/reducers'

import asinUsage from './asinUsage'
import auth from './auth'
import autoMerge from './autoMerge'
import cache from './cache'
import orgs from './orgs'
import { buildReducer } from './ui'
import {
    DynamicReducerKey,
    injectUiReducer,
    getAllAsyncReducerKeys,
    getDefaultState,
    DynamicReducerOptions,
} from './ui/ui'

const filterPersistentKeys: string[] = [
    AD_ACCOUNTS_SUMMARY_PAGE,
    CAMPAIGNS_SUMMARY_PAGE,
    DSP_SUMMARY_PAGE,
    EVENTS_SUMMARY_PAGE,
    LABELS_SUMMARY_PAGE,
    PORTFOLIOS_SUMMARY_PAGE,
    PRODUCTS_SUMMARY_PAGE,
    SOV_KEYWORDS_SUMMARY_PAGE,
    TARGETING_SUMMARY_PAGE,
    WALMART_CAMPAIGNS_SUMMARY_PAGE,
    WALMART_ITEMS_SUMMARY_PAGE,
    WALMART_KEYWORDS_SUMMARY_PAGE,
]

const keysToHydrateAnchoredFilters: string[] = [
    CAMPAIGN_PAGE,
    TARGETING_SUMMARY_PAGE,
]

const uiCommonPersistedKeys = ['tableSettings', FILTER_SETTINGS, 'hydrated']

const uiKeyTransformations = createTransform(
    (inboundState, key) => {
        const keysToStore = filterPersistentKeys.includes(key.toString())
            ? [...uiCommonPersistedKeys, FILTERS]
            : uiCommonPersistedKeys

        return deepPick(inboundState, keysToStore)
    },
    (outboundState, key) => {
        if (keysToHydrateAnchoredFilters.includes(key.toString())) {
            return deepOverride(
                outboundState,
                cloneDeep(getDefaultState() as any)[key],
                'anchored'
            )
        }

        return outboundState
    }
)

const uiPersistConfig = {
    key: 'ui',
    storage,
    stateReconciler: autoMerge,
    transforms: [uiKeyTransformations],
    blacklist: [...getAllAsyncReducerKeys()],
}

const authPersistConfig = {
    key: 'auth',
    storage,
    stateReconciler: autoMerge,
    whitelist: ['userIsImpersonating', 'organizationId', 'organizationGroupId'],
}

export const injectReducer = (
    key: DynamicReducerKey,
    asyncReducer: ReduxCompatibleReducer<any, any>,
    options: DynamicReducerOptions = {}
): void => {
    const persistConfig: PersistConfig<GenericObject> = {
        key: `ui.${key}`,
        storage,
        stateReconciler: autoMerge,
        transforms: [uiKeyTransformations],
        blacklist: [
            'resource',
            'resourceParams',
            'mounting',
            'error',
            'updating',
            'widgets',
            'localFilters',
            'addingLabels',
            'hydrated',
        ],
    }

    if (options.version) {
        persistConfig.version = options.version
    }

    if (options.migrations) {
        persistConfig.migrate = options.migrations
    }

    const persistedReducer = persistReducer(persistConfig, asyncReducer)

    injectUiReducer(key, persistedReducer)
}

export const buildRootReducer = (): ReduxCompatibleReducer<any, any> =>
    combineReducers({
        auth: persistReducer(
            authPersistConfig,
            auth as ReduxCompatibleReducer<any, any>
        ),
        orgs,
        ui: persistReducer(uiPersistConfig, buildReducer()),
        cache,
        asinUsage,
    })
