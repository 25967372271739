import { createAction } from 'redux-actions'

import { curryActionForPage } from 'helpers/curry'

// Mount Page
export const mountPageRequest = createAction('MOUNT_PAGE_REQUEST')
export const mountPageSuccess = createAction('MOUNT_PAGE_SUCCESS')
export const mountPageFailure = createAction('MOUNT_PAGE_FAILURE')

export const makeMountPageRequest = curryActionForPage(mountPageRequest)
export const makeMountPageSuccess = curryActionForPage(mountPageSuccess)
export const makeMountPageFailure = curryActionForPage(mountPageFailure)

// Unmount Page
export const unmountPageRequest = createAction('UNMOUNT_PAGE_REQUEST')
export const makeUnmountPageRequest = curryActionForPage(unmountPageRequest)

// Fetch data
export const fetchPageDataRequest = createAction('FETCH_PAGE_DATA_REQUEST')
export const fetchPageDataSuccess = createAction('FETCH_PAGE_DATA_SUCCESS')
export const fetchPageDataFailure = createAction('FETCH_PAGE_DATA_FAILURE')

export const makeFetchPageDataRequest = curryActionForPage(fetchPageDataRequest)
export const makeFetchPageDataSuccess = curryActionForPage(fetchPageDataSuccess)
export const makeFetchPageDataFailure = curryActionForPage(fetchPageDataFailure)
