import {
    AD_ACCOUNT_TYPES,
    COUNTRIES,
    DATES,
    LABELS,
    METRIC_FILTERS,
    PRODUCT_ASINS,
    PRODUCT_TITLES,
    SC_ACCOUNTS,
    SC_INTEGRATIONS,
    VC_PROGRAMS,
} from 'const/filters'
import { calculateFromDateWithLag } from 'helpers/dateRange'
import { isNonEmptyArray } from 'helpers/typeGuard'
import { formatDateFromFilter } from 'helpers/utils'
import { FiltersState, VcApiSalesDiagnosticFactsParams } from 'types'

import { formatMetricFiltersForParams } from './metrics'
import { formatBooleanFilter } from '../filters'

export const formatFilters = (
    filters: FiltersState
): VcApiSalesDiagnosticFactsParams => {
    let params: VcApiSalesDiagnosticFactsParams = {}

    const dates = filters[DATES]
    const { start, end } = formatDateFromFilter(
        dates,
        calculateFromDateWithLag(filters.rangeLag),
        filters.rangeLag
    )
    params.report_date_min = start
    params.report_date_max = end

    const countries = filters[COUNTRIES]
    if (isNonEmptyArray(countries)) {
        params.marketplace__in = countries.map((item) => item.value).join()
    }

    const asins = filters[PRODUCT_ASINS]
    if (isNonEmptyArray(asins)) {
        params.asin__in = asins.map((item) => item.value).join()
    }

    const labels = filters[LABELS]
    if (isNonEmptyArray(labels)) {
        params.complex_label_filter = formatBooleanFilter(filters[LABELS])
    }

    const titles = filters[PRODUCT_TITLES]
    if (isNonEmptyArray(titles)) {
        params.product_id__in = titles.map((item) => item.value).join()
    }

    const metricFilters = filters[METRIC_FILTERS]
    if (isNonEmptyArray(metricFilters)) {
        params = { ...params, ...formatMetricFiltersForParams(metricFilters) }
    }

    const adAccountTypes = filters[AD_ACCOUNT_TYPES]
    if (isNonEmptyArray(adAccountTypes)) {
        params.account_type__in = adAccountTypes
            .map((item) => item.value)
            .join()
    }

    if (isNonEmptyArray(filters[SC_ACCOUNTS])) {
        params.seller_central_account_id__in = filters[SC_ACCOUNTS].map(
            (item) => item.value
        ).join()
    } else if (isNonEmptyArray(filters[SC_INTEGRATIONS])) {
        params.seller_central_account__integrations__id__in = filters[
            SC_INTEGRATIONS
        ].map((item) => item.value).join()
    }

    const programs = filters[VC_PROGRAMS]
    if (isNonEmptyArray(programs)) {
        params.program__in = programs.map((item) => item.value).join()
    }

    return params
}
