import {
    Point,
    TooltipFormatterContextObject,
    TooltipOptions,
} from 'highcharts'
import { TFunction } from 'i18next'
import get from 'lodash/get'
import { DurationInputArg1, DurationInputArg2 } from 'moment-timezone'

import { isPercentMetric } from 'components/Widgets/TimeSeriesWidget/helpers/utils'
import { UNDEFINED_VALUE } from 'const/formatting'
import { formatTooltip } from 'helpers/charts'
import { formatDate } from 'helpers/dates'
import { formatNumber, HC_DATE_FORMAT } from 'helpers/formatting'
import { elementToString } from 'helpers/utilities'
import { CustomEvent, MetricType } from 'types'
import moment from 'utilities/moment'

interface PriorPeriodConfig {
    diff: DurationInputArg1
    unit: DurationInputArg2
    format: string
    hasData: boolean
}

export type PointType = Point | TooltipFormatterContextObject

type CompareMetricDataPoint = {
    color?: string | undefined
    prior?: string
    current?: string
    symbol?: any
}

interface CompareMetricData {
    [metricName: string]: CompareMetricDataPoint
}

export const renderTooltip = (
    t: TFunction,
    getMetricFormat: (point: PointType) => string,
    customEvents: CustomEvent[],
    priorPeriodConfig: PriorPeriodConfig,
    showAddEventButton: boolean,
    getMetricType: (point: PointType) => MetricType,
    customDateFormat?: string,
    formatAsWeek: boolean = false,
    showPercents: boolean = false
): TooltipOptions => ({
    xDateFormat: HC_DATE_FORMAT.full,
    shared: true,
    backgroundColor: undefined,
    borderWidth: 0,
    shadow: false,
    useHTML: true,
    distance: 10,
    stickOnContact: true,
    style: {
        pointerEvents: 'auto',
    },
    formatter() {
        const { x, points } = this
        // points should always be defined since we're using a shared tooltip
        if (!points) {
            return null
        }

        const date = moment(x)
        const events = customEvents.filter((event) =>
            date.isSame(event.date, 'day')
        )

        const getPercent = (point: any): string => {
            const total = points.reduce((acc: number, p: any) => acc + p.y, 0)
            return `${((point.y / total) * 100).toFixed(2)}%`
        }

        const percentageTypeMetric = (point: PointType): boolean => {
            const metricType = getMetricType(point)
            return isPercentMetric(metricType)
        }

        const renderPoints = points
            .map(
                (point: any) => `
                    ${formatTooltip(
                        point.color,
                        elementToString(point.series.name),
                        formatNumber(point.y, getMetricFormat(point)),
                        showPercents ? getPercent(point) : undefined,
                        point.series.userOptions.showlegendSymbol
                            ? point.series.legendSymbol ??
                                  point.series.legendItem
                            : undefined,
                        percentageTypeMetric(point)
                    )}
                `
            )
            .join(' ')

        const renderEvents = events
            .map(
                (event) => `
            <li>
                <div>
                    <svg height="14px" width="14px" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#C4CAD3" d="M13 2.44989V9.41587C13 9.53519 12.9514 9.64963 12.8649 9.734C12.7784 9.81837 12.6611 9.86576 12.5387 9.86576H7.07437C7.05537 9.86495 7.03649 9.8691 7.01968 9.87778C7.00287 9.88646 6.98874 9.89936 6.97875 9.91514L3.68875 12.7988C3.64655 12.8606 3.58941 12.9112 3.52237 12.9463C3.45533 12.9814 3.38046 12.9998 3.30438 13C3.26516 12.9998 3.22612 12.9948 3.18812 12.9854C3.09504 12.961 3.01202 12.909 2.95055 12.8366C2.88908 12.7642 2.8522 12.6749 2.845 12.5812L2.72687 9.97001C2.72363 9.94123 2.70957 9.91466 2.68741 9.8954C2.66524 9.87615 2.63655 9.86559 2.60687 9.86576H1.46125C1.33892 9.86576 1.2216 9.81837 1.1351 9.734C1.0486 9.64963 1 9.53519 1 9.41587V2.44989C1 2.33057 1.0486 2.21614 1.1351 2.13177C1.2216 2.0474 1.33892 2 1.46125 2H12.5387C12.6611 2 12.7784 2.0474 12.8649 2.13177C12.9514 2.21614 13 2.33057 13 2.44989Z" />
                    </svg>
                </div>
                <div class="event-description">
                    ${event.description}
                </div>
            </li>`
            )
            .join(' ')

        const renderAddEventButton = showAddEventButton
            ? `
            <div class="add-event-btn">
                <button onclick="event.preventDefault(); event.stopPropagation(); openAddNewEventModal(${x})">
                    <span class="plus-icon">+</span> ${t(
                        'dashboards:TimeSeriesWidget.tooltip.eventAnnotation',
                        'Event Annotation'
                    )}
                </button>
            </div>
        `
            : ''

        if (priorPeriodConfig.hasData) {
            const currentPeriodDate = date.format(priorPeriodConfig.format)
            const priorPeriodDate = date
                .subtract(priorPeriodConfig.diff, priorPeriodConfig.unit)
                .format(priorPeriodConfig.format)

            const metricData: CompareMetricData = {}

            points.forEach((point: any): void => {
                const metric = elementToString(point.series.name)
                const symbol = point.series.userOptions.showlegendSymbol
                    ? point.series.legendSymbol ?? point.series.legendItem
                    : undefined
                const isPriorPeriod = get(point, [
                    'series',
                    'options',
                    'isPriorPeriod',
                ])

                const pointData: CompareMetricDataPoint = {
                    [isPriorPeriod ? 'prior' : 'current']: formatNumber(
                        point.y,
                        getMetricFormat(point)
                    ),
                    ...(!isPriorPeriod && {
                        color: point.color as string,
                        symbol,
                    }), // use color of current period point, prior point color is too pale
                }

                metricData[metric] = {
                    ...(metricData[metric] && metricData[metric]),
                    ...pointData,
                }
            })

            const renderMetrics = Object.entries(metricData)
                .map(([name, data]) => {
                    data.symbol?.symbol?.attr({
                        x: 0,
                        y: 0,
                        height: 10,
                        width: 10,
                    })
                    const indicator = data?.symbol?.symbol
                        ? `<svg style="margin-right: 5px;" width='10' height='10'>${data.symbol.symbol.element.outerHTML}</svg>`
                        : ''
                    return `<tr>
                                    <td class="point-label" style="color: ${
                                        data.color
                                    }; text-align: left;">${indicator} ${name}:</td>
                                    <td class="point-value">${
                                        data?.current
                                            ? data.current
                                            : UNDEFINED_VALUE
                                    }</td>
                                    <td class="point-value prior">${
                                        data?.prior
                                            ? data.prior
                                            : UNDEFINED_VALUE
                                    }</td>
                                </tr>`
                })
                .join(' ')

            return `
                <div class="tooltip-data">
                    <table class="points">
                        <tr>
                            <th />
                            <th>${currentPeriodDate}</th>
                            <th class="prior">${priorPeriodDate}</th>
                        </tr>
                        ${renderMetrics}
                    </table>
                </div>
                <div class="events">
                    ${events.length > 0 ? `<ul>${renderEvents}</ul>` : ''}
                    ${renderAddEventButton}
                </div>
            `
        }

        return `
                <div>
                    <div class="tooltip-data">
                        <h3>${formatDate(
                            x,
                            null,
                            customDateFormat,
                            formatAsWeek
                        )}</h3>
                        <div class="points">${renderPoints}</div>
                    </div>
                    <div class="events">
                        ${events.length > 0 ? `<ul>${renderEvents}</ul>` : ''}
                        ${renderAddEventButton}
                    </div>
                </div>
	    `
    },
})
